import { Login } from 'mad-mint'
import { useSession } from 'next-auth/react'
import Home from './index'

export default function LoginMainPage() {
  const { data: session, status } = useSession()
  return (
    <>
      { status == 'authenticated' ? (<Home />) : (<Login className="login-page" />) }
    </>
  )
}

